import {filter, map} from 'rxjs/operators';

import {RESET_DATA_START} from '../actionConstants';
import {resetData} from '../actions';

export const resetDataStartEpic = action$ =>
  action$.pipe(
    filter(action => action.type === RESET_DATA_START),
    map(resetData),
  );
