import {combineReducers} from 'redux';
import authReducer from './authReducer';
import appGlobalReducer from './globalReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  global: appGlobalReducer,
});

export default rootReducer;
