export const RQ_CREATE_EVENT_SIGNER = {
  url: 'v2/event-signers/create',
  method: 'POST',
  auth: true,
};

export const RQ_SEARCH_EVENT_SIGNER = {
  url: 'v2/event-signers/search?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_UPDATE_EVENT_SIGNER = {
  url: 'v2/event-signers/update',
  method: 'PUT',
  auth: true,
};
