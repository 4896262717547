export const RQ_GET_SEARCH_SCOPE_DATA_API = {
  url: 'v2/scope-master/search?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_CREATE_SCOPE_API = {
  url: 'v2/scope-master/save',
  method: 'POST',
  auth: true,
};

export const RQ_GET_SCOPE_BY_ID_API = {
  url: 'v2/scope-master/findById/<%= id %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_RESUBMIT_SCOPE_API = {
  url: 'v2/scope-master/resubmit',
  method: 'PUT',
  auth: true,
};

export const RQ_APPROVE_SCOPE_API = {
  url: 'v2/scope-master/approve-scope',
  method: 'PUT',
  auth: true,
};

export const RQ_REJECT_SCOPE_API = {
  url: 'v2/scope-master/reject-scope',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_SCOPE_HISTORY_API = {
  url: 'v2/scope-master/get-history/<%= ownerId %>',
  method: 'GET',
  auth: true,
};

export const RQ_UPDATE_SCOPE_HISTORY = {
  url: 'v2/scope-master/version-update',
  method: 'PUT',
  auth: true,
};
