import React, {Suspense} from 'react';
import {DashboardIndex} from '../../../screens/DashboardIndex';
import {authLoader} from '../../loader';
import {ROUTE_HOME} from '../../RoutePathConstant';
import {Spin} from 'antd';

const DashboardIndexNavigation = {
  index: true,
  path: ROUTE_HOME,
  element: (
    <Suspense fallback={<Spin />}>
      <DashboardIndex />
    </Suspense>
  ),
  loader: authLoader,
};

export default DashboardIndexNavigation;
