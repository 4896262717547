import PropTypes from 'prop-types';
import {selectAuth} from '../../../redux/selectors';
import {connect} from 'react-redux';
import {Input, Table} from 'antd';
import React, {useEffect, useState} from 'react';
import {isEmpty} from 'lodash';

const SearchTable = ({data, columns, dataSource, showGlobalSearch = false}) => {

  let displayedColumns = columns.map(column => {
    column['onCell']=  () => {
      return {
        style: {minWidth: 100, maxWidth: 400},
      };
    };
    return column;
  });

  const [searchText, setSearchedText] = useState('');
  const [filteredDataSource, setFilteredDataSource] = useState(dataSource);

  const displayedColumnKeys = columns?.map((_, index) => `col${index}`);

  const columnRenderMap = columns?.reduce((map, col, index) => {
    map[`col${index}`] = col.render || (text => text);
    return map;
  }, {});

  const handleSearch = e => {
    const searchValue = e.target.value;
    setSearchedText(searchValue);

    if (searchValue === '') {
      setFilteredDataSource(dataSource);
      return;
    }

    const filterData = dataSource.filter(row => {
      return displayedColumnKeys.some(key => {
        const renderFunction = columnRenderMap[key];
        let value;

        if (renderFunction) {
          try {
            value = renderFunction( value, row);
          } catch (err) {
            console.error(`Error rendering value for key ${key}:`, err);
            value = undefined;
          }
        }

        if (!isEmpty(value)) {
          return value.toString().toLowerCase().includes(searchValue.toLowerCase());
        }
        return false;
      });
    });

    setFilteredDataSource(filterData);
  };

  useEffect(() => {
    setFilteredDataSource(dataSource);
  }, [dataSource]);

  return (
    <>
      {showGlobalSearch && (
        <Input
          placeholder={'Global Search'}
          value={searchText}
          onChange={handleSearch}
          style={{
            marginBottom: '10px',
            fontSize: '1.1em',
            width: '25%',
            float: 'right',
          }}
        />
      )}
      <Table
        columns={displayedColumns}
        dataSource={filteredDataSource}
        rowKey={'id'}
        size={'large'}
        bordered
        defaultPageSize={1}
        pagination={{defaultPageSize: 25}}
        loading={!data}
        scroll={{
          x: 'max-content',
          y: '80vh',
        }}
      />
    </>
  );
};

SearchTable.propTypes = {
  data: PropTypes.array,
  dataSource: PropTypes.any,
  columns: PropTypes.any,
  showGlobalSearch: PropTypes.bool,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(SearchTable);
