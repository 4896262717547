export const RQ_CREATE_KOL_SCOPE_EVENT_API = {
  url: 'v2/kol-event/save',
  method: 'POST',
  auth: true,
};

export const RQ_GET_EVENT_TYPE_API = {
  url: 'v2/event-definition/find-kol-events/<%= eventName %>',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_KOL_EVENT_SEARCH_API = {
  url: 'v2/kol-event/search?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_KOL_EVENT_BY_ID_API = {
  url: 'v2/kol-event/findById/<%= id %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_APPROVE_KOL_EVENT_API = {
  url: 'v2/kol-event/approve-kol-event',
  method: 'PUT',
  auth: true,
};

export const RQ_REJECT_KOL_EVENT_API = {
  url: 'v2/kol-event/reject-kol-event',
  method: 'PUT',
  auth: true,
};

export const RQ_RESUBMIT_KOL_EVENT_API = {
  url: 'v2/kol-event/resubmit',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_KOL_WALLET_BALANCE_API = {
  url: 'v2/wallet-balance/balance-for-brands/<%= eventDate %>/<%= eventDefinitionId %>/<%= locationId %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_UPDATE_KOL_ADVANCE_API = {
  url: 'v2/kol-event/updateAdvancePayments/<%= eventId %>',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_EVENT_SPONSOR_DETAILS_BY_ID_API = {
  url: 'v2/kol-event/details/for-event-sponsor/<%= kolEventId %>',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_MARK_ABORT_KOL_EVENT_API ={
  url: 'v2/kol-event/mark-aborted-event/<%= kolEventId %>',
  method: 'PUT',
  auth: true,
};

export const RQ_MARK_CONDUCT_KOL_SCOPE_API={
  url: 'v2/kol-scope-event/mark-conducted/<%= kolScopeEventId %>/<%= conductedDateTime %>',
  method: 'PUT',
  auth: true,
};

export const RQ_MARK_RESCHEDULE_KOL_SCOPE_API={
  url: 'v2/kol-scope-event/mark-rescheduled/<%= kolScopeEventId %>/<%= rescheduledDateTime %>',
  method: 'PUT',
  auth: true,
};

export const RQ_MARK_ABORT_KOL_SCOPE_API={
  url: 'v2/kol-scope-event/mark-aborted/<%= kolScopeEventId %>',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_KOL_SCOPES_BY_EVENT_ID_API={
  url: 'v2/kol-scope-event/search-by-kol-event/<%= id %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_GET_KOL_SCOPE_BY_ID_API = {
  url: 'v2/kol-scope-event/findById/<%= id %>',
  method: 'GET',
  auth: true,
  search: true,
};

export const RQ_PUT_KOL_SCOPE_CLOSURE_API = {
  url: 'v2/kol-scope-event/kol-scope-closure',
  method: 'PUT',
  auth: true,
  search: true,
};
export const RQ_GET_KOL_EVENT_FOR_HOSPOC_SEARCH_API = {
  url: 'v2/kol-event/search/ho-spoc?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_KOL_SCOPES_API = {
  url: 'v2/kol-scope-event/search?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_PUT_APPROVE_KOL_SCOPE_API = {
  url: 'v2/kol-scope-event/approve-kol-scope-closure',
  method: 'PUT',
  auth: true,
};

export const RQ_PUT_REJECT_KOL_SCOPE_API = {
  url: 'v2/kol-scope-event/reject-kol-scope-Closure',
  method: 'PUT',
  auth: true,
};

export const RQ_RESUBMIT_KOL_SCOPE_API = {
  url: 'v2/kol-scope-event/resubmit',
  method: 'PUT',
  auth: true,
};

export const RQ_VALIDATE_KOL_SCOPE_API = {
  url: 'v2/kol-scope-event/validate/<%= scopeId %>',
  method: 'PUT',
  auth: true,
};

export const RQ_KOL_SCOPE_SAVE_PAYMENT_API = {
  url: 'v2/kol-scope-event/save-payments/<%= scopeId %>',
  method: 'POST',
  auth: true,
};

export const RQ_KOL_SCOPE_FINAL_FT_APPROVE = {
  url: 'v2/kol-scope-event/ft-event-action-with-payment',
  method: 'PUT',
  auth: true,
};
