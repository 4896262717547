import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {
  ROUTE_ADD_EXCEPTION_REPORTS,
  ROUTE_EXCEPTION_REPORTS, ROUTE_VIEW_EXCEPTION_REPORTS,
} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const ExceptionReporting = lazy(() =>
  import('../../../screens/ExceptionReporting')
    .then(module => ({default: module.ExceptionReporting})));

const AddExceptionReport = lazy(() =>
  import('../../../screens/ExceptionReporting')
    .then(module => ({default: module.AddExceptionReport})));

const ViewExceptionReport = lazy(() =>
  import('../../../screens/ExceptionReporting')
    .then(module => ({default: module.ViewExceptionReport})));

const ExceptionReportingNavigation = [
  {
    path: ROUTE_EXCEPTION_REPORTS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ExceptionReporting />
      </Suspense>
    ),
  },
  {
    path: ROUTE_ADD_EXCEPTION_REPORTS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <AddExceptionReport />
      </Suspense>
    ),
  },
  {
    path: ROUTE_VIEW_EXCEPTION_REPORTS,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ViewExceptionReport />
      </Suspense>
    ),
  },
];

export default ExceptionReportingNavigation;
