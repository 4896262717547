import {createReducer} from './reducerUtils';
import {
  HIDE_SPINNER,
  RESET_MESSAGE,
  SHOW_MESSAGE,
  SHOW_SPINNER,
} from '../actionConstants';
const initialState = {
  showSpinner: false,
  showMessage: false,
  messageObj: null,
};

const showSpinner = (state = initialState) => {
  return {
    ...state,
    showSpinner: true,
    showMessage: false,
  };
};

const hideSpinner = (state = initialState ) => {
  return {
    ...state,
    showSpinner: false,
  };
};

const showMessage = (state = initialState, payload) => {
  return {
    ...state,
    showMessage: true,
    messageObj: payload.message,
  };
};

const resetMessage = (state = initialState ) => {
  return {
    ...state,
    showMessage: false,
    messageObj: null,
  };
};

export default createReducer(initialState, {
  [SHOW_SPINNER]: showSpinner,
  [HIDE_SPINNER]: hideSpinner,
  [SHOW_MESSAGE]: showMessage,
  [RESET_MESSAGE]: resetMessage,
});
