import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {selectAuth} from '../../redux/selectors';
import {connect} from 'react-redux';
import {Row} from 'antd';
import {ROUTE_LOGIN} from '../../navigations/RoutePathConstant';

const CommonAuthenticate = ({auth}) => {

  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.loggedIn) {
      navigate(ROUTE_LOGIN);
    }
  }, [auth.loggedIn, navigate]);

  return (
    <Row />
  );
};

CommonAuthenticate.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapState = state => {
  const auth = selectAuth(state);
  return {auth};
};

export default connect(mapState)(CommonAuthenticate);
