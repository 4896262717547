import React from 'react';
import PropTypes from 'prop-types';
import {selectGlobal} from '../../../redux/selectors';
import {connect} from 'react-redux';
import {LoadingSpinner} from '../../common/LoadingSpinner';

const GlobalLoadingSpinner = ({global}) => {
  return global.showSpinner ? (
    <LoadingSpinner />
  ) : null;
};

GlobalLoadingSpinner.propTypes = {
  global: PropTypes.object.isRequired,
};

const mapState = state => {
  const global = selectGlobal(state);
  return {global};
};

export default connect(mapState)(GlobalLoadingSpinner);
