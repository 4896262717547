import React, {useEffect} from 'react';
import {RouterProvider} from 'react-router-dom';
import * as PropTypes from 'prop-types';
import {identifyAuthExistOnDeviceStart} from '../redux/actions';
import {connect} from 'react-redux';
import {AppRouter} from './AppRouter';

const RootNavigator = ({handleIdentifyUserExistOnDeviceStart}) => {

  useEffect(() => {
    handleIdentifyUserExistOnDeviceStart();
  }, [handleIdentifyUserExistOnDeviceStart]);

  return (
    <RouterProvider router={AppRouter} />
  );
};

RootNavigator.propTypes = {
  handleIdentifyUserExistOnDeviceStart: PropTypes.func.isRequired,
};

const actions = {
  handleIdentifyUserExistOnDeviceStart: identifyAuthExistOnDeviceStart,
};

export default connect(null, actions)(RootNavigator);
