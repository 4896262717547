import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {RQ_CREATE_EXCEPTION_EXPENSE, RQ_GET_EXCEPTION_EXPENSE_BY_ID, RQ_GET_EXPENSE_HISTORY_API, RQ_SEARCH_EXCEPTION_EXPENSE, RQ_UPDATE_EXCEPTION_EXPENSE} from './constants';
import {useQuery} from '@tanstack/react-query';

export const RQPostCreateExceptionReport = async ({
  expense,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_EXCEPTION_EXPENSE,
    JSON.stringify({...expense}),
    token,
    null,
    signal,
  );
};

export const RQSearchExceptionReport = payload => {
  return useQuery(
    ['rq_get_all_templates', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_SEARCH_EXCEPTION_EXPENSE,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
  );
};

export const RQGetExceptionReportById = payload => {
  return useQuery(
    ['rq_get_template_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EXCEPTION_EXPENSE_BY_ID,
        null,
        token,
        {id:id},
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQPutExceptionReport = async ({
  expense,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_EXCEPTION_EXPENSE,
    JSON.stringify({...expense}),
    token,
    null,
    signal,
  );
};

export const RQGetExpenseHistoryByOwnerId = payload => {
  return useQuery(
    ['rq_get_expense_history_by_owner_id', payload],
    async ({queryKey, signal}) => {
      const {token, ownerId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_EXPENSE_HISTORY_API,
        null,
        token,
        {ownerId:ownerId},
        signal,
      );
    },
  );
};
