import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {RQ_CREATE_EVENT_SIGNER, RQ_SEARCH_EVENT_SIGNER, RQ_UPDATE_EVENT_SIGNER} from './constants';
import {useQuery} from '@tanstack/react-query';

export const RQPostCreateEventSigner = async ({
  eventSigner,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_EVENT_SIGNER,
    JSON.stringify({...eventSigner}),
    token,
    null,
    signal,
  );
};

export const RQSearchEventSigner = payload => {
  return useQuery(
    ['rq_get_all_templates', payload],
    async ({queryKey, signal}) => {
      const {token} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_SEARCH_EVENT_SIGNER,
        null,
        token,
        null,
        signal,
      );
    },
  );
};

export const RQPutEventSigner = async ({
  eventSigner,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_EVENT_SIGNER,
    JSON.stringify({...eventSigner}),
    token,
    null,
    signal,
  );
};
