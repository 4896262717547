export const PANCARD_DOC_ID = 'DOCFG00000056ff30c0018b8569d976007f99';
export const CANCEL_CHEQUE_ID = 'DOCFG00000056ff30c0018b8569d976007f98';
export const GST_DOC_ID = 'DOCFG00000056ff30c0018b8569d976007f97';
export const KOL_DOC_ID = 'DOCFG000000248c6e27018b239510vg765812';

//vendor
export const GST_CERTIFICATE_ID = 'DOCFG0000004a1afbfc018b89297016007ff1';
export const MSME_CERTIFICATE_ID = 'DOCFG0000004a1afbfc018b89297016007ff0';

export const ADMIN_JOB_ROLE_ID = 'JOBTT000000000000000000000000000ADMIN';
export const FT_JOB_ROLE_ID = 'JOBTT202200000000000000000000000000ft';
export const FA_JOB_ROLE_ID = 'JOBTT202100000000000000000000000000fa';
export const BM_JOB_ROLE_ID = 'JOBTT00000000000000000000000000000129';
export const GM_JOB_ROLE_ID = 'JOBTT20210000000000000000000000000002';
export const ABM_JOB_ROLE_ID = 'JOBTT20210000000000000000000000000003';
export const HO_SPOC_JOB_ROLE_ID = 'JOBTT000000000000000000000000000000hs';
export const MEDICAL_TEAM_JOB_ROLE_ID = 'JOBRO0000002214ab580191da22bdc9007fc4';
