import {MainDashboardIndex} from '../../screensComponents/DashboardIndexScreen/MainDashboardIndex';
import {CommonAuthenticate} from '../CommonAuthenticate';

const DashboardIndex = () => {
  return (
    <>
      <CommonAuthenticate />
      <MainDashboardIndex />
    </>
  );
};

export default DashboardIndex;
