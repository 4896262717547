import {Col, Row} from 'antd';

const MainIndex = () => {
  return (
    <Row>
      <Col span={24}>Welcome!!!</Col>
    </Row>
  );
};

export default MainIndex;
