import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {useQuery} from '@tanstack/react-query';
import {
  RQ_CREATE_BRAND_API,
  RQ_GET_BRANDS_FOR_KOL_EVENT_API, RQ_GET_BRAND_BY_ID_API,
  RQ_GET_BRAND_FOR_LOCATION_USER_API, RQ_GET_BRAND_LIST_API, RQ_GET_MY_BRAND_API,
  RQ_GET_SEARCH_BRAND_API,
  RQ_UPDATE_BRAND_ALL_FIELDS_API,
} from './constants';

export const RQPostCreateBrand = async ({
  brand,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_BRAND_API,
    JSON.stringify({...brand}),
    token,
    null,
    signal,
  );
};

export const RQUseGetBrandListData = payload => {
  return useQuery(
    ['rq_get_brand_list_data', payload],
    async ({queryKey, signal}) => {
      const {token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_BRAND_LIST_API,
        null,
        token,
        null,
        signal,
      );
    },
    {
      cacheTime: 2 * 60 * 1000, // 2 min
    },
  );
};

export const RQUseGetBrandByIdData = payload => {
  return useQuery(
    ['rq_get_brand_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_BRAND_BY_ID_API,
        null,
        token,
        {id},
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQPutBrandUpdateAllField = async ({
  brand,
  token,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_BRAND_ALL_FIELDS_API,
    JSON.stringify({...brand, id:id}),
    token,
    null,
    signal,
  );
};

export const RQUseGetSearchBrandData = payload => {
  return useQuery(
    ['rq_get_search_brand_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];

      return rqCreateSearchRequest(
        RQ_GET_SEARCH_BRAND_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQUseGetBrandForLocationUser = (payload, options={}) => {
  return useQuery(
    ['rq_get_brand_location_user', payload],
    async ({queryKey, signal}) => {
      const {locationId, userId, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_BRAND_FOR_LOCATION_USER_API,
        null,
        token,
        {locationId, userId},
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQUseGetBrandForUser = (payload, options={}) => {
  return useQuery(
    ['rq_get_brand_user', payload],
    async ({queryKey, signal}) => {
      const {userId, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_MY_BRAND_API,
        null,
        token,
        {userId},
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQUseGetBrandFoKolEvent = (payload, options = {}) => {
  return useQuery(
    ['rq_get_brand_for_kol_event', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_BRANDS_FOR_KOL_EVENT_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};
