import {HIDE_SPINNER, SHOW_ERROR, SHOW_SPINNER, SHOW_SUCCESS} from '../../actionConstants';

export const messages = {
  LOGIN_START: [
    {action: SHOW_SPINNER},
  ],
  LOGIN_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Login successful!', type: 'success'}},
    },
  ],
  LOGIN_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Authentication failed!', type: 'error'}},
    },
  ],
  LOGOUT_START: [
    {action: SHOW_SPINNER},
  ],
  LOGOUT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Logout successful!', type: 'success'}},
    },
  ],
  LOGOUT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Logout failed!', type: 'warn'}},
    },
  ],
  CREATE_BRAND_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Brand created successfully!', type: 'success'}},
    },
  ],
  CREATE_BRAND_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Brand create failed!', type: 'error'}},
    },
  ],
  UPDATE_BRAND_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Brand updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_BRAND_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Brand update failed!', type: 'error'}},
    },
  ],
  CREATE_DIVISION_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Division created successfully!', type: 'success'}},
    },
  ],
  CREATE_DIVISION_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Division create failed!', type: 'error'}},
    },
  ],
  UPDATE_DIVISION_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Division updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_DIVISION_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Division update failed!', type: 'error'}},
    },
  ],
  CREATE_JOB_ROLE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Job Role created successfully!', type: 'success'}},
    },
  ],
  CREATE_JOB_ROLE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Job Role create failed!', type: 'error'}},
    },
  ],
  UPDATE_JOB_ROLE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Job Role updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_JOB_ROLE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Job Role update failed!', type: 'error'}},
    },
  ],
  CREATE_BUDGET_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Budget created successfully!', type: 'success'}},
    },
  ],
  CREATE_BUDGET_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Budget create failed!', type: 'error'}},
    },
  ],
  UPDATE_BUDGET_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Budget updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_BUDGET_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Budget update failed!', type: 'error'}},
    },
  ],
  CREATE_FREEZE_ALLOCATION_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Freeze Allocation created successfully!', type: 'success'}},
    },
  ],
  CREATE_FREEZE_ALLOCATION_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Freeze Allocation create failed!', type: 'error'}},
    },
  ],
  UPDATE_FREEZE_ALLOCATION_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Freeze Allocation updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_FREEZE_ALLOCATION_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Freeze Allocation update failed!', type: 'error'}},
    },
  ],
  CREATE_DOCUMENT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Document created successfully!', type: 'success'}},
    },
  ],
  CREATE_DOCUMENT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Document create failed!', type: 'error'}},
    },
  ],
  UPDATE_DOCUMENT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Document updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_DOCUMENT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Document update failed!', type: 'error'}},
    },
  ],
  WALLET_UPLOAD_SUCCESSFUL:[
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'File upload successfully!', type: 'success'}},
    },
  ],
  WALLET_UPLOAD_FAIL:[
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'File upload failed!', type: 'error'}},
    },
  ],
  CREATE_APPROVAL_CHAIN_DEFINITION_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Approval Chain created successfully!', type: 'success'}},
    },
  ],
  CREATE_APPROVAL_CHAIN_DEFINITION_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Approval Chain create failed!', type: 'error'}},
    },
  ],
  UPDATE_APPROVAL_CHAIN_DEFINITION_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Approval Chain updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_APPROVAL_CHAIN_DEFINITION_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Approval Chain update failed!', type: 'error'}},
    },
  ],
  CREATE_EVENT_CONFIGURATION_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Event Configuration created successfully!', type: 'success'}},
    },
  ],
  CREATE_EVENT_CONFIGURATION_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Event Configuration create failed!', type: 'error'}},
    },
  ],
  UPDATE_EVENT_CONFIGURATION_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Event Configuration updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_EVENT_CONFIGURATION_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Event Configuration update failed!', type: 'error'}},
    },
  ],
  EVENT_CONFIGURATION_ALREADY_EXISTS: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Event Configuration already exists!', type: 'error'}},
    },
  ],
  WALLET_UPDATE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'File update successfully!', type: 'success'}},
    },
  ],
  WALLET_UPDATE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'File update failed!', type: 'error'}},
    },
  ],
  HCP_REGISTRATION_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Doctor register successfully!', type: 'success'}},
    },
  ],
  HCP_REGISTRATION_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Doctor register failed!', type: 'error'}},
    },
  ],
  CREATE_VIEW_REQUEST_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'View Request created successfully!', type: 'success'}},
    },
  ],
  CREATE_VIEW_REQUEST_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'View Request create failed!', type: 'error'}},
    },
  ],
  CREATE_REALLOCATION_REQUEST_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Reallocation created successfully!', type: 'success'}},
    },
  ],
  CREATE_REALLOCATION_REQUEST_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Reallocation create failed!', type: 'error'}},
    },
  ],
  REJECT_REALLOCATION_REQUEST_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Reallocation request rejected successfully!', type: 'success'}},
    },
  ],
  REJECT_REALLOCATION_REQUEST_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Reallocation request rejection failed!', type: 'error'}},
    },
  ],
  APPROVE_REALLOCATION_REQUEST_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Reallocation request approved successfully!', type: 'success'}},
    },
  ],
  APPROVE_REALLOCATION_REQUEST_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Reallocation request approval failed!', type: 'error'}},
    },
  ],
  CREATE_LOCATION_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Location created successfully!', type: 'success'}},
    },
  ],
  CREATE_LOCATION_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Location create failed!', type: 'error'}},
    },
  ],
  UPDATE_LOCATION_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Location updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_LOCATION_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Location update failed!', type: 'error'}},
    },
  ],
  EVENT_CREATION_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Event created successfully!', type: 'success'}},
    },
  ],
  EVENT_CREATION_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Event create failed!', type: 'error'}},
    },
  ],
  CREATE_USER_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'User created successfully!', type: 'success'}},
    },
  ],
  CREATE_USER_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'User create failed!', type: 'error'}},
    },
  ],
  UPDATE_USER_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'User updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_USER_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'User update failed!', type: 'error'}},
    },
  ],
  APPROVE_HCP_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Doctor Approve successfully!', type: 'success'}},
    },
  ],
  APPROVE_HCP_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Please enter approval comments!', type: 'error'}},
    },
  ],
  REJECT_HCP_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Doctor Reject successfully!', type: 'success'}},
    },
  ],
  REJECT_HCP_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Please enter approval comments!', type: 'error'}},
    },
  ],
  VENDOR_CREATION_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Vendor created successfully!', type: 'success'}},
    },
  ],
  VENDOR_CREATION_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Vendor create failed!', type: 'error'}},
    },
  ],
  CREATE_SECURITY_ROLE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Security Role created successfully!', type: 'success'}},
    },
  ],
  CREATE_SECURITY_ROLE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Security Role create failed!', type: 'error'}},
    },
  ],
  UPDATE_SECURITY_ROLE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Security Role updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_SECURITY_ROLE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Security Role update failed!', type: 'error'}},
    },
  ],
  APPROVE_EVENT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Approve event successfully!', type: 'success'}},
    },
  ],
  APPROVE_EVENT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Approve event failed!', type: 'error'}},
    },
  ],
  DOCTOR_UPLOAD_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Doctor Upload successfully!', type: 'success'}},
    },
  ],
  DOCTOR_UPLOAD_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Doctor Upload failed!', type: 'error'}},
    },
  ],

  APPROVE_VENDOR_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Vendor Save successfully!', type: 'success'}},
    },
  ],
  APPROVE_VENDOR_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Please enter code & approval comments!', type: 'error'}},
    },
  ],
  REJECT_VENDOR_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Vendor Reject successfully!', type: 'success'}},
    },
  ],
  REJECT_EVENT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Event Rejected successfully', type: 'success'}},
    },
  ],
  REJECT_EVENT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Event Rejection failed', type: 'error'}},
    },
  ],
  VENDOR_EVENT_CREATION_SUCCESSFUL:[
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Event Created successfully', type: 'success'}},
    },
  ],
  VENDOR_EVENT_CREATION_FAIL:[
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Event Creation failed', type: 'error'}},
    },
  ],
  APPROVE_VENDOR_EVENT_SUCCESSFUL:[
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Event Approved successfully', type: 'success'}},
    },
  ],
  APPROVE_VENDOR_EVENT_FAIL:[
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Event Approval failed', type: 'error'}},
    },
  ],
  REJECT_VENDOR_EVENT_SUCCESSFUL:[
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Event Rejected successfully', type: 'success'}},
    },
  ],
  REJECT_VENDOR_EVENT_FAIL:[
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Event Rejection failed', type: 'error'}},
    },
  ],
  RESUBMIT_VENDOR_SUCCESSFUL:[
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Vendor Resubmit successfully', type: 'success'}},
    },
  ],
  RESUBMIT_VENDOR_FAIL:[
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Vendor Resubmit failed', type: 'error'}},
    },
  ],
  CONFIG_MASTER_SAVE_SUCCESS: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Configuration saved successfully!', type: 'success'}},
    },
  ],
  CONFIG_MASTER_SAVE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Configuration saved failed!', type: 'error'}},
    },
  ],
  BULK_APPROVAL_EVENT_SUCCESS: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Events approved successfully!', type: 'success'}},
    },
  ],
  BULK_APPROVAL_EVENT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Event approval failed!', type: 'error'}},
    },
  ],
  PULL_BACK_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Pull back request saved successfully!', type: 'success'}},
    },
  ],
  PULL_BACK_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Pull back request failed!', type: 'error'}},
    },
  ],
  UPDATE_FMV_DETAILS_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'FMV and bank details updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_FMV_DETAILS_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'FMV and bank details update failed!', type: 'error'}},
    },
  ],
  UPDATE_DOCUMENTS_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Documents updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_DOCUMENTS_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Documents update failed!', type: 'error'}},
    },
  ],
  CREATE_SCOPE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Scope created successfully!', type: 'success'}},
    },
  ],
  CREATE_SCOPE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Scope create failed!', type: 'error'}},
    },
  ],
  RESUBMIT_SCOPE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Scope resubmit successfully!', type: 'success'}},
    },
  ],
  RESUBMIT_SCOPE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Scope resubmit failed!', type: 'error'}},
    },
  ],
  APPROVE_SCOPE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Scope approved successfully!', type: 'success'}},
    },
  ],
  APPROVE_SCOPE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Please enter approval comments!', type: 'error'}},
    },
  ],
  REJECT_SCOPE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Scope rejected successfully!', type: 'success'}},
    },
  ],
  REJECT_SCOPE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Please enter approval comments!', type: 'error'}},
    },
  ],
  CREATE_TEMPLATE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Template creation Successful!', type: 'success'}},
    },
  ],
  CREATE_TEMPLATE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Template creation failed!!', type: 'error'}},
    },
  ],
  UPDATE_TEMPLATE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Template updated Successfully!', type: 'success'}},
    },
  ],
  UPDATE_TEMPLATE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Template update failed!', type: 'error'}},
    },
  ],
  TEMPLATE_ALREADY_EXISTS: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Template already exists for that owner type!', type: 'error'}},
    },
  ],
  CREATE_CANCELLATION_REASON_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Reason creation Successful!', type: 'success'}},
    },
  ],
  CREATE_CANCELLATION_REASON_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Reason creation failed!!', type: 'error'}},
    },
  ],
  UPDATE_CANCELLATION_REASON_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Reason updated Successfully!', type: 'success'}},
    },
  ],
  UPDATE_CANCELLATION_REASON_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Reason update failed!', type: 'error'}},
    },
  ],
  APPROVE_EVENT_CONFIGURATION_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Event Configuration approved Successfully!', type: 'success'}},
    },
  ],
  APPROVE_EVENT_CONFIGURATION_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Event Configuration approval failed!', type: 'error'}},
    },
  ],
  REJECT_EVENT_CONFIGURATION_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Event Configuration rejected Successfully!', type: 'success'}},
    },
  ],
  REJECT_EVENT_CONFIGURATION_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Event Configuration rejection failed!', type: 'error'}},
    },
  ],
  RESUBMIT_EVENT_CONFIGURATION_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Event Configuration resubmitted Successfully!', type: 'success'}},
    },
  ],
  RESUBMIT_EVENT_CONFIGURATION_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Event Configuration resubmit failed!', type: 'error'}},
    },
  ],
  UPDATE_SCOPE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Scope updated successfully!', type: 'success'}},
    },
  ],
  UPDATE_SCOPE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Scope update failed!', type: 'error'}},
    },
  ],
  CREATE_KOL_EVENT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Kol event  created successfully!', type: 'success'}},
    },
  ],
  CREATE_KOL_EVENT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Kol event  creation failed!', type: 'error'}},
    },
  ],
  APPROVE_KOL_EVENT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Kol event approved successfully!', type: 'success'}},
    },
  ],
  APPROVE_KOL_EVENT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Kol event approve failed!', type: 'error'}},
    },
  ],
  REJECT_KOL_EVENT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Kol event rejected successfully!', type: 'success'}},
    },
  ],
  REJECT_KOL_EVENT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Kol event reject failed!', type: 'error'}},
    },
  ],
  RESUBMIT_KOL_EVENT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Kol event resubmit successfully!', type: 'success'}},
    },
  ],
  RESUBMIT_KOL_EVENT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Kol event resubmit failed', type: 'error'}},
    },
  ],
  CREATE_EVENT_APPROVAL_AMOUNT_CONFIG_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Event approval amount config creation Successful!', type: 'success'}},
    },
  ],
  CREATE_EVENT_APPROVAL_AMOUNT_CONFIG_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Event approval amount config creation failed!!', type: 'error'}},
    },
  ],
  UPDATE_EVENT_APPROVAL_AMOUNT_CONFIG_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Event approval amount config updated Successfully!', type: 'success'}},
    },
  ],
  UPDATE_EVENT_APPROVAL_AMOUNT_CONFIG_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Event approval amount config update failed!', type: 'error'}},
    },
  ],
  KOL_SCOPE_MARK_CONDUCT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Kol scope conducted successfully!', type: 'success'}},
    },
  ],
  KOL_SCOPE_MARK_CONDUCT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Kol scope conduct failed!', type: 'error'}},
    },
  ],
  KOL_SCOPE_MARK_ABORT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Kol scope aborted successfully!', type: 'success'}},
    },
  ],
  KOL_SCOPE_MARK_ABORT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Kol scope abort failed!', type: 'error'}},
    },
  ],
  KOL_SCOPE_MARK_RESCHEDULE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Kol scope rescheduled successfully!', type: 'success'}},
    },
  ],
  KOL_SCOPE_MARK_RESCHEDULE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Kol scope reschedule failed!', type: 'error'}},
    },
  ],
  MARK_ABORT_KOL_EVENT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Kol Event aborted successfully!', type: 'success'}},
    },
  ],
  MARK_ABORT_KOL_EVENT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Kol Event abort failed!', type: 'error'}},
    },
  ],
  KOL_SCOPE_INITIATE_CLOSURE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Kol Scope closure process initiated successfully!', type: 'success'}},
    },
  ],
  KOL_SCOPE_INITIATE_CLOSURE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Kol Scope closure process initiate failed!', type: 'error'}},
    },
  ],
  APPROVE_KOL_SCOPE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Kol scope approved successfully!', type: 'success'}},
    },
  ],
  APPROVE_KOL_SCOPE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Kol scope approve failed!', type: 'error'}},
    },
  ],
  REJECT_KOL_SCOPE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Kol scope rejected successfully!', type: 'success'}},
    },
  ],
  REJECT_KOL_SCOPE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Kol scope reject fail!', type: 'error'}},
    },
  ],
  RESUBMIT_KOL_SCOPE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Kol scope resubmit successfully!', type: 'success'}},
    },
  ],
  RESUBMIT_KOL_SCOPE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Kol scope resubmit failed', type: 'error'}},
    },
  ],
  PAYMENT_UPLOAD_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Payment upload successfully!', type: 'success'}},
    },
  ],
  PAYMENT_UPLOAD_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Payment uplaod failed', type: 'error'}},
    },
  ],
  CREATE_EXCEPTION_REPORT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Expense creation Successful!', type: 'success'}},
    },
  ],
  CREATE_EXCEPTION_REPORT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Expense creation failed!!', type: 'error'}},
    },
  ],
  UPDATE_EXCEPTION_REPORT_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Expense updated Successfully!', type: 'success'}},
    },
  ],
  UPDATE_EXCEPTION_REPORT_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Expense update failed!', type: 'error'}},
    },
  ],
  CREATE_EVENT_SIGNER_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Event Signer creation Successful!', type: 'success'}},
    },
  ],
  CREATE_EVENT_SIGNER_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Event Signer creation failed!!', type: 'error'}},
    },
  ],
  UPDATE_EVENT_SIGNER_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Event Signer updated Successfully!', type: 'success'}},
    },
  ],
  UPDATE_EVENT_SIGNER_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Event Signer update failed!', type: 'error'}},
    },
  ],
  UPDATE_DOCTOR_ERP_CODE_SUCCESSFUL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_SUCCESS,
      payload: {message: {text: 'Doctor Erp Code updated Successfully!', type: 'success'}},
    },
  ],
  UPDATE_DOCTOR_ERP_CODE_FAIL: [
    {action: HIDE_SPINNER},
    {
      action: SHOW_ERROR,
      payload: {message: {text: 'Doctor Erp Code update failed!', type: 'error'}},
    },
  ],
};
