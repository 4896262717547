import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';
import {
  ROUTE_UPDATE_DOCTOR_ERP_CODE,
} from '../../RoutePathConstant';
import {authLoader} from '../../loader';

const UpdateDoctorErpCode = lazy(() =>
  import('../../../screens/UpdateDoctorErpCode')
    .then(module => ({default: module.UpdateDoctorErpCode})));

const EventSignerNavigation = [
  {
    path: ROUTE_UPDATE_DOCTOR_ERP_CODE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <UpdateDoctorErpCode />
      </Suspense>
    ),
  },
];

export default EventSignerNavigation;
