import React from 'react';
import {GlobalLoadingSpinner} from './GlobalLoadingSpinner';
import {GlobalMessage} from './GlobalMessage';

const GlobalComponent = () => {
  return (
    <>
      <GlobalLoadingSpinner />
      <GlobalMessage />
    </>
  );
};

export default GlobalComponent;
