import {filter, mergeMap} from 'rxjs/operators';

import {
  IDENTIFY_AUTH_EXIST_ON_DEVICE_FAIL,
  LOGOUT_SUCCESSFUL,
} from '../actionConstants';
import {resetDataStart} from '../actions';
import {cleanupLocalStorage} from '../../localStorage';

export const logoutSuccessfulEpic = action$ =>
  action$.pipe(
    filter(
      action =>
        action.type === LOGOUT_SUCCESSFUL ||
        action.type === IDENTIFY_AUTH_EXIST_ON_DEVICE_FAIL,
    ),
    mergeMap(action =>
      cleanupLocalStorage().then(resetDataStart).catch(resetDataStart),
    ),
  );
