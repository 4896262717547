export const RQ_CREATE_EXCEPTION_EXPENSE = {
  url: 'v2/exceptions/create',
  method: 'POST',
  auth: true,
};

export const RQ_SEARCH_EXCEPTION_EXPENSE = {
  url: 'v2/exceptions/search?',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_UPDATE_EXCEPTION_EXPENSE = {
  url: 'v2/exceptions/update',
  method: 'PUT',
  auth: true,
};

export const RQ_GET_EXCEPTION_EXPENSE_BY_ID = {
  url: 'v2/exceptions/findById/<%= id %>',
  method: 'GET',
  search: true,
  auth: true,
};

export const RQ_GET_EXPENSE_HISTORY_API = {
  method: 'GET',
  url: 'v2/exceptions/getHistoryByOwnerId/<%= ownerId %>',
  auth: true,
};
