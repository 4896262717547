import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest} from './rqHttpsUtils';
import {RQ_GET_ALL_DIGI_SIGNERS_BY_EVENT_ID} from './constants';

export const RQGetAllDigiSignersByEventId = (payload, options={}) => {
  return useQuery(
    ['rq_get_all_digi_signers_by_event_id', payload],
    async ({queryKey, signal}) => {
      const {eventId, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_ALL_DIGI_SIGNERS_BY_EVENT_ID,
        null,
        token,
        {eventId: eventId},
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};
