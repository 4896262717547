import {RESET_DATA, RESET_DATA_START} from '../actionConstants';

export const resetDataStart = () => dispatch => {
  dispatch({
    type: RESET_DATA_START,
  });
};

export const resetData = () => dispatch => {
  dispatch({
    type: RESET_DATA,
  });
};
