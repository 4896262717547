import {useQuery} from '@tanstack/react-query';
import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {RQ_APPROVE_SCOPE_API, RQ_CREATE_SCOPE_API, RQ_GET_SCOPE_BY_ID_API, RQ_GET_SCOPE_HISTORY_API, RQ_GET_SEARCH_SCOPE_DATA_API, RQ_REJECT_SCOPE_API, RQ_RESUBMIT_SCOPE_API, RQ_UPDATE_SCOPE_HISTORY} from './constants';

export const RQGetSearchScopeData = payload => {
  return useQuery(
    ['rq_get_search_scope_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_SEARCH_SCOPE_DATA_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQPostCreateScope = async ({
  scope,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_SCOPE_API,
    JSON.stringify({...scope}),
    token,
    null,
    signal,
  );
};

export const RQUseGetScopeByIdData = payload => {
  return useQuery(
    ['rq_get_scope_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_SCOPE_BY_ID_API,
        null,
        token,
        {id:id},
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQPostResubmitScope = async ({
  scope,
  token,
  signal,
  id,
}) => {
  return rqCreateRequest(
    RQ_RESUBMIT_SCOPE_API,
    JSON.stringify({...scope, id:id}),
    token,
    null,
    signal,
  );
};

export const RQPutApproveScope = async ({
  scopeData,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_APPROVE_SCOPE_API,
    JSON.stringify({...scopeData}),
    token,
    null,
    signal,
  );
};

export const RQPutRejectScope = async ({
  reject,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REJECT_SCOPE_API,
    JSON.stringify({...reject}),
    token,
    null,
    signal,
  );
};

export const RQGetScopeHistoryByOwnerId = payload => {
  return useQuery(
    ['rq_get_scope_history_by_owner_id', payload],
    async ({queryKey, signal}) => {
      const {token, ownerId} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_SCOPE_HISTORY_API,
        null,
        token,
        {ownerId:ownerId},
        signal,
      );
    },
  );
};

export const RQUpdateScopeHistory = async ({
  scope,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_SCOPE_HISTORY,
    JSON.stringify({...scope}),
    token,
    null,
    signal,
  );
};
