import {useQuery} from '@tanstack/react-query';
import {
  RQ_APPROVE_EVENT_DEFINITION,
  RQ_APPROVE_REJECT_VENDOR_EVENT_API,
  RQ_CREATE_EVENT_CONFIGURATION_API,
  RQ_FT_APPROVE_REJECT_VENDOR_EVENT_API, RQ_GET_CONDUCTED_STATUS_OF_EVENT,
  RQ_GET_EVENT_CONFIGURATION_BY_EVENT_NAME_API,
  RQ_GET_EVENT_CONFIGURATION_BY_ID_API,
  RQ_GET_EVENT_CONFIGURATION_LIST_API,
  RQ_GET_EVENT_CONFIGURATION_LIST_BY_NAME_API,
  RQ_GET_EVENT_DEFINITION_FIND_BY_BRAND_API,
  RQ_GET_EVENT_REPORT_BY_EVENT_ID_API,
  RQ_GET_SEARCH_EVENT_CONFIGURATION_API,
  RQ_GET_WALLET_BALANCE_BY_EVENT_API,
  RQ_GET_WALLET_BALANCE_FOR_EVENT_API,
  RQ_RESUBMIT_EVENT_DEFINITION,
  RQ_UPDATE_EVENT_CONFIGURATION_ALL_FIELDS_API,
} from './constants';
import dayjs from 'dayjs';
import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';

export const RQPostCreateEventConfiguration = async ({
  eventConfig,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_EVENT_CONFIGURATION_API,
    JSON.stringify({...eventConfig}),
    token,
    null,
    signal,
  );
};

export const RQUseGetEventConfigurationListData = payload => {
  return useQuery(
    ['rq_get_event_configuration_list_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EVENT_CONFIGURATION_LIST_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 2 * 60 * 1000, // 2 min
    },
  );
};

export const RQUseGetEventConfigurationByIdData = (payload, options = {}) => {
  return useQuery(
    ['rq_get_event_configuration_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EVENT_CONFIGURATION_BY_ID_API,
        null,
        token,
        {id: id},
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQUseGetEventReportedByEventIdData = (payload, options = {}) => {
  return useQuery(
    ['rq_get_event_report_by_event_id_api', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EVENT_REPORT_BY_EVENT_ID_API,
        null,
        token,
        {id: id},
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQUseGetEventConfigurationBalance = (payload, options = {}) => {
  return useQuery(
    ['rq_get_event_configuration_balance_data', payload],
    async ({queryKey, signal}) => {
      const {eventDate, eventDefinitionId, locationId, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_WALLET_BALANCE_BY_EVENT_API,
        null,
        token,
        {
          eventDate: dayjs(eventDate).format('YYYY-MM-DD'),
          eventDefinitionId: eventDefinitionId, locationId: locationId,
        },
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQUseGetEventClosureTransaction = (payload, options = {}) => {
  return useQuery(
    ['rq_get_event_closure_transaction_data', payload],
    async ({queryKey, signal}) => {
      const {eventId, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_WALLET_BALANCE_FOR_EVENT_API,
        null,
        token,
        {eventId: eventId},
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQPutEventConfigurationUpdateAllField = async ({
  eventConfig,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_EVENT_CONFIGURATION_ALL_FIELDS_API,
    JSON.stringify({...eventConfig}),
    token,
    null,
    signal,
  );
};

export const RQUseGetSearchEventConfigurationData = payload => {
  return useQuery(
    ['rq_get_search_event_configuration_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];

      return rqCreateSearchRequest(
        RQ_GET_SEARCH_EVENT_CONFIGURATION_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQUseGetEventConfigurationByEventNameData = payload => {
  return useQuery(
    ['rq_get_event_configuration_by_event_name_data', payload],
    async ({queryKey, signal}) => {
      const {token, eventName} = queryKey[1];

      return rqCreateSearchRequest(
        RQ_GET_EVENT_CONFIGURATION_BY_EVENT_NAME_API,
        null,
        token,
        {eventName: eventName},
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQVendorEventApproveReject = async ({
  details,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_APPROVE_REJECT_VENDOR_EVENT_API,
    JSON.stringify({...details}),
    token,
    null,
    signal,
  );
};

export const RQFtVendorEventApproveReject = async ({
  eventData,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_FT_APPROVE_REJECT_VENDOR_EVENT_API,
    JSON.stringify({...eventData}),
    token,
    null,
    signal,
  );
};

export const RQUseGetEventDefinitionByBrandData = payload => {
  return useQuery(
    ['rq_get_event_definition_by_brand_data', payload],
    async ({queryKey, signal}) => {
      const {token, brandId, eventName} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_EVENT_DEFINITION_FIND_BY_BRAND_API,
        null,
        token,
        {brandId},
        signal,
        {'eventName': eventName},
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQEventDefinitionApprove = async ({
  eventDefinition,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_APPROVE_EVENT_DEFINITION,
    JSON.stringify({...eventDefinition}),
    token,
    null,
    signal,
  );
};

export const RQEventDefinitionReject = async ({
  eventDefinition,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_APPROVE_EVENT_DEFINITION,
    JSON.stringify({...eventDefinition}),
    token,
    null,
    signal,
  );
};

export const RQEventDefinitionReSubmit = async ({
  eventConfig,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_RESUBMIT_EVENT_DEFINITION,
    JSON.stringify({...eventConfig}),
    token,
    null,
    signal,
  );
};

export const RQUseGetEventConfigurationListByNameData = (payload, options = {}) => {
  return useQuery(
    ['rq_get_event_configuration_list_by_name_data', payload],
    async ({queryKey, signal}) => {
      const {filter,  token} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_EVENT_CONFIGURATION_LIST_BY_NAME_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQGetConductedStatusOfEvent = (payload, options = {}) => {
  return useQuery(
    ['rq_get_event_configuration_conducted_status', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_CONDUCTED_STATUS_OF_EVENT,
        null,
        token,
        {eventId: id},
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};
