import {Row, Spin} from 'antd';
import React from 'react';
import {Loading3QuartersOutlined} from '@ant-design/icons';
import './LoadingSpinner.less';

const spinnerIcon = <Loading3QuartersOutlined className={'spinner-icon'} spin />;

const LoadingSpinner = () => {
  return (
    <Row className={'spinner-container'}>
      <Spin
        spinning={true}
        size={'large'}
        indicator={spinnerIcon}
      />
    </Row>
  );
};

export default LoadingSpinner;
