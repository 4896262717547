import {Index} from '../../../screens/Index';
import React from 'react';
import {appIndexAuthLoader} from '../../loader';

const IndexNavigation = {
  index: true,
  element: <Index />,
  loader: appIndexAuthLoader,
};

export default IndexNavigation;
