import React from 'react';
import {MainLayout} from './MainLayout';

const MainHome = () => {
  return (
    <MainLayout />
  );
};

export default MainHome;
