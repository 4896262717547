import {Checkbox} from 'antd';

const PullBackSearchColumns =  (auth, updateSelectedWalletBalanceMap, selectedBalanceList, approveAll) => [
  {
    title: <Checkbox key={'pullbackSelectAll'} onChange={ e => approveAll(e)}>Select All</Checkbox>,
    render: (_, record, index) => {
      return (
        <>
          <Checkbox key={`${record.walletBalanceId}_${index}`}
            onChange={ e =>{
              updateSelectedWalletBalanceMap(e, record.walletBalanceId);
            }}
            checked={selectedBalanceList.indexOf(record.walletBalanceId)>-1}
          />
        </>
      );
    },
  },
  {
    title: 'SR No',
    render: (_, record, index) => index + 1,
  },
  {
    title: 'Location',
    dataIndex: 'location',
    render: (_, record, index) => record.location.name,
  },
  {
    title: 'Employee',
    dataIndex: 'employee',
    render: (_, record, index) => record.employee.name,
  },
  {
    title: 'Brand',
    dataIndex: 'brand',
    render: (_, record, index) => record.brand.name,
  },
  {
    title: 'Budget Type',
    dataIndex: 'budgetType',
    render: (_, record, index) => record.budgetType.name,
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    render: (_, record, index) => record.balance,
  },
];
export default PullBackSearchColumns;
